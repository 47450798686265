/**
 * Button
 */

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import css from './Button.module.scss';

const Button = ({ element: Element, theme, className, children, ...rest }) => (
  <Element className={classnames(css.root, css[theme], className)} {...rest}>
    {children}
  </Element>
);

Button.propTypes = {
  theme: PropTypes.oneOf(['dark']),
  children: PropTypes.node,
  className: PropTypes.string,
};

Button.defaultProps = {
  element: 'button'
}

export default Button;
