/**
 * FullscreenVideo
 */

/* eslint-disable jsx-a11y/media-has-caption */

import React, { Component, createRef } from 'react';
import Hls from 'hls.js';
import { isOffline } from '@utils';

export default class FullscreenVideo extends Component {
  constructor(props) {
    super(props);

    this.videoRef = this.props.videoRef || createRef(null);
  }

  componentDidMount() {
    const { inView } = this.props;
    this.useHLS = Hls.isSupported() && !isOffline;

    if (this.useHLS && inView) {
      this.initializeVideo();
    }
  }

  componentDidUpdate(prevProps) {
    // Init HLS if video is in view
    if (prevProps.inView !== this.props.inView && this.props.inView) {
      this.initializeVideo();
    } else if (prevProps.inView === true && this.props.inView === false) {
      this.destroyVideoInstance();
    }
  }

  componentWillUnmount() {
    this.destroyVideoInstance();
  }

  destroyVideoInstance = () => {
    const video = this.videoRef.current;

    if (video) {
      video.pause();
      video.removeAttribute('src');
      // video.load();
    }
  }

  getVideoFile = file => {
    const { path } = this.props;
    const storageUrl = process.env.GATSBY_STORAGE_URL;

    return `${storageUrl}${path}/${file}`;
  }

  initializeVideo = () => {
    const { autoPlay } = this.props;
    const hls = new Hls();
    const m3u8 = this.getVideoFile('index.m3u8');
    const video = this.videoRef.current;

    if (isOffline) {
      video.play();
    } else if(this.useHLS) {
      hls.loadSource(m3u8);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoPlay) {
          video.play();
        }
      });
    }
   // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
   // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
   // This is using the built-in support of the plain video element, without using hls.js.
    else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = m3u8;
      video.addEventListener('canplay', () => {
        if (autoPlay) {
          video.play();
        }
      });
    }
  }

  render() {
    const { className, poster, loop, preload, muted } = this.props;
    const hlsVideo = this.getVideoFile('index.m3u8');
    const offlineVideo = this.getVideoFile('original.mp4');

    return (
      <video
        autoPlay
        className={className}
        loop={loop}
        muted={muted}
        poster={poster}
        preload={preload}
        ref={this.videoRef}
      >
        {!isOffline && hlsVideo && <source src={hlsVideo} type="application/vnd.apple.mpegurl" />}
        {offlineVideo && isOffline && <source src={offlineVideo} type='video/mp4' />}
      </video>
    )
  }
}
