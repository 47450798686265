const camelCase = require('lodash/camelCase');

const capitalize = text => {
  if (typeof text === 'string') {
    const firstLetter = text.substr(0, 1).toUpperCase()
    return `${firstLetter}${text.substr(1, text.length)}`
  }

  return text
}

const getTemplatePath = (templateName) => {
  // Exclude pages with these templates set in Wordpress
  const excluded = ['Status', 'Gatsby', 'Frontpage', 'Default', 'Download'];

  const template = templateName ? capitalize(
    camelCase(
      templateName
        .replace('page-', '')
        .replace('.php', '')
    )
  ) : 'Default';

  if (excluded.indexOf(template) >= 0) {
    return null;
  }

  return `./src/templates/${template}/index.js`;
}

module.exports = getTemplatePath;