import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';

// const getLevelByError = error => {
//   switch (error) {
//     case 'bufferNudgeOnStall':
//     case 'levelLoadError':
//     case 'bufferSeekOverHole':
//     case 'bufferFullError':
//     case 'levelLoadTimeOut':
//     case 'fragParsingError':
//     case 'fragLoadError':
//     case 'fragLoadTimeOut':
//       return 'warning';
//     case 'manifestLoadError':
//     default:
//       return 'error';
//   }
// }

export default class HLSSource extends Component {
  static propTypes = {
    path: PropTypes.string,
    src: PropTypes.string,
  }

  constructor(props, context) {
    super(props, context);
    this.hls = new Hls();
  }

  componentDidMount() {
    // `src` is the property get from this component
    // `video` is the property insert from `Video` component
    // `video` is the html5 video element
    const { src, video } = this.props;

    // load hls video source base on hls.js
    if (Hls.isSupported()) {
      this.hls.loadSource(src);
      this.hls.attachMedia(video);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });

      // this.hls.on(Hls.Events.ERROR, function (event, data) {
      //   const ignore = ['bufferStalledError'];
      //   console.error('HLS error', event, data);

      //   if (!ignore.includes(data.details)) {
      //     Sentry.configureScope((scope) => {
      //       scope.setUser(getUserData());
      //       scope.setLevel(getLevelByError(data.details));
      //       scope.setExtra(event, data.details);
      //       if (data.frag) {
      //         Object.keys(data.frag).forEach(key => {
      //           scope.setExtra(key, data.frag[key]);
      //         });
      //       }
      //     });
  
      //     const relurl = get(data, 'frag.relurl');
  
      //     Sentry.captureMessage(`${event} (${data.details}) for ${path} ${relurl ? `(${relurl})` : ''}`);
      //   }
      // });
    }
  // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
   // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
   // This is using the built-in support of the plain video element, without using hls.js.
   else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.src = src;
    video.addEventListener('canplay', () => {
      video.play();
    });
  }
  }

  componentWillUnmount() {
    // destroy hls video source
    if (this.hls) {
      this.hls.destroy();
    }
  }

  render() {
    return (
      <source
        src={this.props.src}
        type={this.props.type || 'application/x-mpegURL'}
      />
    );
  }
}
