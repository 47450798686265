/**
 * DefaultMainMenu
 * 
 * Note: This is the menu for the "Full Collection"
 */

import React from 'react';
import get from 'lodash/get';
import { StaticQuery, graphql } from 'gatsby';
import { MainMenu } from '@components';

const DefaultMainMenu = () => (
  <StaticQuery
    query={graphql`
      query {
        categories: allWordpressPage(
          filter: { template: { eq: "page-category-frontpage.php" } }
          sort: { fields: menu_order, order: ASC }
        ) {
          edges {
            node {
              path
              title
              menu_order
              acf {
                background_image {
                  source_url
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1400, srcSetBreakpoints: [700]) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const items = get(data, 'categories.edges', []) || [];

      if (!items.length) {
        return null;
      }

      const menuItems = items.map(({ node: { path, title, acf: { background_image} } }) => ({
        label: title,
        path,
        image: background_image,
      }));
      
      return (
        <MainMenu
          shortcut={{
            to: '/virtual-content',
            label: 'Go to virtual content'
          }}
          items={
            [
              {
                label: 'Collection',
                path: '/collection',
                image: null,
              }
            ].concat(menuItems)
          }
        />
      );
    }}
  />
);

export default DefaultMainMenu;
