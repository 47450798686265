/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/assets/styles/global.scss';
import 'react-overlayscrollbars/dist/es/index.css';

// InteractionObserver polyfill (needed for some browsers for gatsby-image to work properly)
import 'intersection-observer';

// Remove Gray Highlight When Tapping Links in Mobile Safari, 
// and then to allow :active styles to work in your CSS on a page in Mobile Safari:
document.addEventListener("touchstart", () => {}, true);
