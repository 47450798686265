export const cubicBezier = 'cubic-bezier(0, 0.99, 0.25, 1)'

export const MQ = {
  xs: 'min-width: 480px',
  sm: 'min-width: 768px',
  md: 'min-width: 1024px',
  lg: 'min-width: 1280px',
}

export const colors = {
  accent: 'rgb(255, 55, 58)'
}