/**
 * ToolsMenu
 */

import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import { isOffline } from '@utils';
import css from './ToolsMenu.module.css';

const STORAGE_KEYS = {
  PRESENTATION_MODE: 'is_presentation_mode',
  FULLSCREEN_MODE: 'is_fullscreen_mode',
};

const toggleFullscreen = (event) => {
  var element = document.body;

	if (event instanceof HTMLElement) {
		element = event;
	}

	var isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

	element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function () { return false; };
	document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function () { return false; };

	isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
}

const ToolsMenuItem = ({ title, sub, active, icon, ...rest }) => (
  <button className={classnames(css.menu__item, { [css.active]: active })} {...rest}>
    {icon && (
      <div className={css.menu__item__icon}>
        {icon}
      </div>
    )}
    <div className={css.menu__item__content}>
      <div className={css.menu__item__content__title}>
        {title}
      </div>
      {sub && <div className={css.menu__item__content__sub}>
        {sub}
      </div>}
    </div>
  </button>
);

const ToolsMenu = ({ onSignOut }) => {
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const timeoutRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [fullscreenEnabled, setFullscreenEnabled] = useState(false);
  const [cursorInactive, setCursorInactive] = useState(false);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [presentationMode, setPresentationMode] = useState(false);

  const toggleMenu = () => setOpen(!open);
  const togglePresentationMode = () => setPresentationMode(!presentationMode);
  const toggleFullscreenMode = () => {
    toggleFullscreen();
    setFullscreenMode(!fullscreenMode)
  };

  // Persist presentation mode on mount
  useEffect(() => {
    setPresentationMode(JSON.parse(localStorage.getItem(STORAGE_KEYS.PRESENTATION_MODE)) || false);
    setFullscreenEnabled(!!document.fullscreenEnabled);
  }, []);

  /**
   * Menu management
   */
  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      if (key === 'Escape' && open) {
        setOpen(false);
        buttonRef.current.focus();
      }
    }

    const handleClickOutside = ({ target }) => {
      const menuEl = get(menuRef, 'current');
      const buttonEl = get(buttonRef, 'current');

      if ((menuEl && !menuEl.contains(target)) && (buttonEl && !buttonEl.contains(target))) {
        setOpen(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClickOutside);
    }
  }, [open]);

  /**
   * Presentation Mode
   */
  useEffect(() => {
    // Persist
    localStorage.setItem(STORAGE_KEYS.PRESENTATION_MODE, presentationMode);

    const handleMouseMove = () => {
      clearTimeout(timeoutRef.current);
      setCursorInactive(false);

      timeoutRef.current = setTimeout(() => {
        setCursorInactive(true);
      }, 3000);
      
      return () => {
        clearTimeout(timeoutRef.current);
      }
    }

    if (presentationMode) {
      document.body.classList.add('is-presentation-mode');
      document.addEventListener('mousemove', handleMouseMove);
    } else {
      document.body.classList.remove('is-presentation-mode');
    }

    return () => {
      clearTimeout(timeoutRef.current);
      document.removeEventListener('mousemove', handleMouseMove);
    }
  }, [presentationMode]);

  // Listen for custor inactive
  useEffect(() => {
    if (cursorInactive) {
      document.body.classList.add('cursor-inactive');
    } else {
      document.body.classList.remove('cursor-inactive');
    }
  }, [cursorInactive]);

  return (
    <div className={classnames(
      css.root, { [css.open]: open }, 
      { [css.presentationMode]: presentationMode }, 
      { 'presentation-mode-control': !open }
    )} aria-hidden>
      <div className={css.positioner}>
        <button onClick={toggleMenu} className={classnames(css.button)} type="button" aria-haspopup="true" aria-expanded={open} ref={buttonRef}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M8 .2C3.7.2.2 3.7.2 8s3.5 7.8 7.8 7.8 7.8-3.5 7.8-7.8S12.3.2 8 .2zm4.7 6.1c-.1.4-.1.8-.3 1.3-.2.6-.6 1.1-1.1 1.4-.7.5-1.4.4-2.2.4H9c-.1 0-.2 0-.4-.1-.1.2-.3.3-.5.5s-.5.4-.7.6c-.4.4-.9.8-1.3 1.3l-.1.1c-.2.3-.4.5-.7.7-.3.2-.6.3-.9.3-.2 0-.5-.1-.6-.2-.5-.3-.7-.9-.5-1.5.1-.5.4-.8.7-1.2.8-.9 1.6-1.7 2.5-2.6-.2-1-.1-2.3.6-3.2.8-.8 2.1-1.1 3.2-.7.1.1.2.2.2.3 0 .1-.1.3-.2.4-.4.3-.7.6-1.1.9 0 .2.1.4.1.7 0 .2.1.4.1.6.5.1.9.2 1.3.2l.3-.1c.4-.2.7-.4 1-.6.1-.1.3-.1.4 0 .1.1.2.2.2.4l.1.1z"/></svg>
        </button>
        <div className={css.menu} ref={menuRef} tabIndex="0" role="menu">
          <ToolsMenuItem 
            active={presentationMode}
            onClick={togglePresentationMode}
            title="Presentation Mode"
            sub="Hides controls and menus when the cursor is inactive"
          />
          {fullscreenEnabled && (
            <ToolsMenuItem
              active={fullscreenMode}
              onClick={toggleFullscreenMode}
              title="Fullscreen Mode"
              sub="Opens the application in full screen"
            />
          )}
          {!isOffline && (
            <ToolsMenuItem 
              icon={<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-test-icon-element="true" focusable="false"><path d="M28.2 14.5h-20l6.2-6.2c.8-.8.8-2 0-2.8s-2-.8-2.8 0l-9.4 9.6c-.8.8-.8 2 0 2.8l9.6 9.6c.8.8 2 .8 2.8 0s.8-2 0-2.8l-6.2-6.2h20c1.2 0 2-.8 2-2s-1-2-2.2-2z"></path></svg>}
              onClick={onSignOut}
              title="Sign out"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ToolsMenu;
