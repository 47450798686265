/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';
import isOnline from 'is-online';

import isBrowser from '@utils/isBrowser';
import AppContext from '@contexts/AppContext';
import { isLoggedIn, validateAuthenticatedUser, logout } from '../services/auth';
import isProtectedPath from '@utils/isProtectedPath';
import { RestrictionsMessage, ToolsMenu } from '@components';

const isOffline = process.env.GATSBY_OFFLINE === 'true';
const noLogin = process.env.GATSBY_NO_LOGIN === 'true';

// if (isBrowser()) {
//   Sentry.init({ 
//     dsn: process.env.GATSBY_SENTRY_DSN,
//     environment: process.env.NODE_ENV,
//   });
// }

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
    }
  }

  componentDidMount() {
    this.checkIfAuthenticated();

    // Save user data for debugging
    // Sentry.configureScope((scope) => {
    //   scope.setUser(getUserData());
    // });
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location } = this.props;

    if (location.pathname !== prevLocation.pathname) {
      this.checkIfAuthenticated();
    }
  }

  // componentDidCatch(error, errorInfo) {
  //   Sentry.configureScope((scope) => {
  //     scope.setUser(getUserData());
  //     Object.keys(errorInfo).forEach(key => {
  //       scope.setExtra(key, errorInfo[key]);
  //     });
  //   });

  //   Sentry.captureException(error);
  // }

  onProjectedRoute = () => {
    return isProtectedPath(this.props.location.pathname);
  };

  /**
   * If user is not logged in
   */
  checkIfAuthenticated = () => {
    const onProjectedRoute = this.onProjectedRoute();
    const { location } = this.props;
    const { isReady } = this.state;

    if (noLogin && !isReady) {
      this.setIsReady()
    } else {
      if (onProjectedRoute && !isReady) {
        if (isBrowser() && !isLoggedIn()) {
          this.redirectToLogin(location);

        /**
         * Validate logged in user if on the web version
         */
        } else if (isLoggedIn()) {
          // On offline we can't validate the authenticated user
          // The user must sign in while they have internet and 
          // afterwards they will be able to access the site without logging in
          (async () => {
            const isCurrentlyOnline = await isOnline();
            if (isOffline && !isCurrentlyOnline) {
              this.setIsReady()
            } else {
              validateAuthenticatedUser(() => {
                // Validation succeeded
                this.setIsReady();
              }, () => {

                // Validation failed
                this.redirectToLogin(location);
              })
            }
          })();
        }
      }
    }
  }

  setIsReady = () => {
    this.setState({
      isReady: true,
    })
  }

  setRedirectPath = path => {
    localStorage.setItem('REDIRECT_URL', path);
  }
  
  redirectToLogin = (location) => {
    this.setRedirectPath(location.pathname);
    navigate(`/login/`);
  }

  handleSignOut = () => {
    logout(() => {
      navigate(`/login/`);
    });
  }

  render() {
    const { location, children } = this.props;
    const { isReady } = this.state;
    const onProjectedRoute = this.onProjectedRoute();

    /**
    * Wait until user is validated before rendering
    */
    if (!isReady && onProjectedRoute) {
      return null;
    }

    return (
        <>
          <Helmet>
            <meta http-equiv="Content-Type" content="text/html;charset=utf-8" />
            <title>{process.env.GATSBY_SITE_NAME}</title>
            <link rel="stylesheet" href="https://use.typekit.net/byf7ppr.css" />
            <meta name="robots" content="noindex, nofollow" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
          </Helmet>
          { !location.pathname.match(/^\/request((\/\w+)+|\/?)$/) && <RestrictionsMessage />}
          <AppContext.Provider value={{
            appIsReady: isReady,
            setAppIsReady: this.setIsReady,
            setRedirectPath: this.setRedirectPath
          }}>
            {children}
            {location.pathname !== '/' && !location.pathname.includes('login') && <ToolsMenu onSignOut={this.handleSignOut} />}
          </AppContext.Provider>
        </>
    )
  }
}

export default Layout
