/**
 * CollectionPremiereHero
*/

import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import { isOffline } from '@utils';
import { FullscreenVideo } from '@components';
import css from './CollectionPremiereHero.module.css';

const CollectionPremiereHero = ({ title, subtitle, videoPath, isActive, logo, ...rest }) => {
  return (
    <div className="section" {...rest}>
      <div className={css.inner}>
        {videoPath && (
          <div className={css.videoBackground}>
            <FullscreenVideo
              autoPlay 
              muted
              loop
              preload="auto"
              path={videoPath}
              inView={isActive}
            />
          </div>
        )}
        <div className={css.content}>
          {logo && (
            <Image
              className={css.logo}
              loading={isOffline ? 'eager' : 'lazy'}
              fluid={get(logo, 'localFile.childImageSharp.fluid')}
              alt=""
            />
          )}
          {/* {title && <h1 className={css.title} dangerouslySetInnerHTML={{ __html: title }} />} */}
          {subtitle && <h2 className={css.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />}
        </div>
      </div>
    </div>
  );
};

export default CollectionPremiereHero;


export const sectionsHero = graphql`
  fragment SectionHero on WordPressAcf_hero {
    ... on WordPressAcf_hero {
      title
      subtitle
      video_path
    }
  }
`
