/**
 * Is proected path?
 */

module.exports = path => {
  if (!path) {
    return false;
  }

  return path !== '/' && 
    !path.match(/^\/login((\/\w+)+|\/?)$/) && 
    !path.match(/^\/request((\/\w+)+|\/?)$/);
};
